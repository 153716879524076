<template>
    <div>

        <!-- Media -->
        <b-media class="mb-2">
            <template #aside>
                <b-avatar
                    ref="previewEl"
                    :src="avatarSrc(reviserData.access.photo)"
                    :text="avatarText(reviserData.access.fullName)"
                    variante="light-success"
                    size="150px"
                    rounded
                />
            </template>
            <h4 class="mb-0 ml-50">{{ reviserData.access.fullName }}</h4>
            <div class="d-flex flex-wrap mt-1">
                <b-button
                    variant="primary"
                    @click="$refs.refInputEl.click()"
                >
                    <input
                        ref="refInputEl"
                        type="file"
                        class="d-none"
                        accept=".jpg,.jpeg,.png"
                        @change="onFileChange"
                    >
                    <span class="d-none d-sm-inline">Actualizar</span>
                    <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none"
                    />
                </b-button>
                <b-button
                    @click="removeAvatar"
                    variant="outline-secondary"
                    class="ml-1"
                >
                    <span class="d-none d-sm-inline">Remover</span>
                    <feather-icon
                        icon="TrashIcon"
                        class="d-inline d-sm-none"
                    />
                </b-button>
            </div>
        </b-media>

        <!-- Form: Info Form -->
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>

                    <!-- Field: Names -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Nombre(s)"
                            label-for="names"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="nombre(s)"
                            >
                                <b-form-input
                                    id="names"
                                    :state="errors.length > 0 ? false:null"
                                    v-model="reviserData.access.names"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Field: Surnames -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Apellido(s)"
                            label-for="surnames"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="apellido(s)"
                            >
                                <b-form-input
                                    id="surnames"
                                    :state="errors.length > 0 ? false:null"
                                    v-model="reviserData.access.surnames"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                </b-row>
                <b-row>

                    <!-- Field: Email -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Correo electrónico"
                            label-for="email"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|email"
                                name="correo electrónico"
                            >
                                <b-form-input
                                    id="email"
                                    :state="errors.length > 0 ? false:null"
                                    v-model="reviserData.access.email"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Field: Password -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Contraseña (en caso desee actualizarla, complete el siguiente campo, sino, dejar vacío)"
                            label-for="a-password"
                        >
                            <b-form-input
                                id="a-password"
                                v-model="reviserData.password"
                                placeholder="********"
                                autocomplete="new-password"
                            />
                        </b-form-group>
                    </b-col>

                </b-row>
            </b-form>
        </validation-observer>

        <!-- Action Buttons -->
        <b-button
            @click="updateInformation"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
            Guardar cambios
        </b-button>
    </div>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BMedia, BAvatar } from 'bootstrap-vue';
    import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { avatarText } from '@core/utils/filter';
    import { ref } from '@vue/composition-api';
    import { required } from '@validations';
    import store from '@/store';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButton,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BForm,
            BMedia,
            BAvatar,

            required
        },
        props: {
            reviserData: {
                type: Object,
                required: true
            }
        },
        /*data() {
            return {
                PublicUrl: process.env.VUE_APP_URL,
            }
        },*/
        methods: {
            avatarSrc (img){
                if (img){
                    if (img.includes('http')){
                        return img;
                    } else {
                        return `${process.env.VUE_APP_FILES}/modules/revisers/${img}`;
                        //return `${this.PublicUrl}/assets/images/modules/revisers/${img}`;
                        //return require(`@/assets/images/modules/revisers/${img}`);
                    }
                } else {
                    return null;
                }
            }
        },
        setup(props) {

            // Init Photo
            const initPhoto = props.reviserData.access.photo;

            // Use Toast
            const toast = useToast();

            const blankData = {
                photo : null,
                initPhoto
            };

            // ? Demo Purpose => Update image on click of update
            const refInputEl = ref(null);
            const previewEl = ref(null);
            const simpleRules = ref(null);

            const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
                props.reviserData.access.photo = base64;
            });

            const onFileChange = (e) => {
                const file = e.target.files[0];
                props.reviserData.changePhoto = 1;
                if (file) {
                    props.reviserData.access.photo = URL.createObjectURL(file);
                    blankData.photo = file;
                } else {
                    props.reviserData.access.photo = null;
                    blankData.photo = null;
                }
            };

            const updateInformation = () =>
            {
                simpleRules.value.validate()
                    .then(success => {
                        if (success) {

                            let reviserData = {
                                ...props.reviserData.access,
                                password: props.reviserData.password,
                                changePhoto: props.reviserData.changePhoto,
                                auth: JSON.parse(localStorage.getItem('userData')).id,
                                ...blankData
                            };

                            store.dispatch('app-reviser/updateReviser', { id: props.reviserData.id, reviserData })
                                .then((response) => {

                                    props.reviserData.password = '';
                                    props.reviserData.access.fullName = `${response.data.reviser.names} ${response.data.reviser.surnames}`;
                                    props.reviserData.access.photo = response.data.photo;
                                    blankData.initPhoto = response.data.photo;
                                    props.reviserData.changePhoto = 0;

                                    toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: response.data.message,
                                            icon: 'CheckIcon',
                                            variant: 'success'
                                        }
                                    });
                                })
                                .catch( (err) => {
                                    let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información del revisor.';
                                    toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: message,
                                            icon: 'AlertTriangleIcon',
                                            variant: 'danger'
                                        }
                                    });
                                });

                        }
                    });
            };

            const removeAvatar = () => {
                props.reviserData.access.photo = null;
                blankData.photo = null;
                props.reviserData.changePhoto = 1;
            };

            return {
                avatarText,
                updateInformation,
                removeAvatar,
                onFileChange,

                refInputEl,
                previewEl,
                simpleRules,
                inputImageRenderer
            }
        }
    }

</script>
