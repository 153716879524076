import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchRevisers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/revisers`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchReviser(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/reviser/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addReviser(ctx, userData) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();

                formData.append("auth", userData.auth);
                formData.append("photo", userData.photo);
                formData.append("names", userData.names);
                formData.append("surnames", userData.surnames);
                formData.append("email", userData.email);
                //formData.append("password", userData.password);

                axios
                    .post(`${process.env.VUE_APP_HOST}/api/dashboard/reviser/create`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateReviser(ctx, { id, reviserData }) {
            return new Promise((resolve, reject) => {

                let formData = new FormData();

                formData.append("accessId", reviserData.id);
                formData.append("photo", reviserData.photo);
                formData.append("changePhoto", reviserData.changePhoto);
                formData.append("initPhoto", reviserData.initPhoto);

                formData.append("names", reviserData.names);
                formData.append("surnames", reviserData.surnames);
                formData.append("email", reviserData.email);
                formData.append("password", reviserData.password);

                axios
                    .put(`${process.env.VUE_APP_HOST}/api/dashboard/reviser/${id}/update`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteReviser(ctx, { id, auth }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/reviser/${id}/delete?auth=${auth}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}